import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import schemaUtils from '../../_helpers/schemaUtils'
import translateUtils from '../../_helpers/translateUtils'
import { contactService } from '../../_services'
import contactSchema from '../../schemas/contacts.schema'
import AbstractLayout from '../Abstract/AbstractLayout'

const Contact = function () {
  const collectionName = 'Contacts'
  const singleCollectionName = 'Contact'
  const queryKey = collectionName.toLowerCase()
  const singleQueryKey = singleCollectionName.toLowerCase()
  const params = useParams()
  const getQuery = reactQuery.getOneQuery(
    singleQueryKey,
    params.id,
    contactService.get
  )
  const updateMutation = reactQuery.updateMutation(
    contactService.update,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const deleteMutation = reactQuery.deleteMutation(
    contactService.remove,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const [body, setBody] = useState({})
  useEffect(() => {
    if (!getQuery.isLoading && !getQuery.data.contacted)
      updateMutation.mutate({ contacted: true })
  }, [getQuery.isLoading])
  return (
    <AbstractLayout
      actions={[
        {
          buttonLabel: `${translateUtils.translate(
            'Update'
          )} ${translateUtils.translate(singleCollectionName)}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            const newBody = objectUtils.compare(getQuery.data, args)
            if (Object.keys(newBody).length)
              return updateMutation.mutate(newBody)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translateUtils.translate(
              'Update'
            )} ${translateUtils.translate(singleCollectionName)}`,
            inputs: schemaUtils.schemaToInputsArray(
              contactSchema,
              getQuery.data
            ),
          },
        },
        {
          buttonLabel: `${translateUtils.translate(
            'Delete'
          )} ${translateUtils.translate(singleCollectionName)}`,
          buttonColor: 'secondary',
          onSubmit: () => deleteMutation.mutate(),
          form: {
            title: `${translateUtils.translate(
              'Delete'
            )} ${translateUtils.translate(singleCollectionName)}`,
          },
          confirm: true,
          info: `${translateUtils.translate(
            'Are you sure you want to delete this'
          )} ${translateUtils.translate(singleCollectionName)}`,
        },
      ]}
      title={singleCollectionName}
      getQuery={getQuery}
      many={false}
    />
  )
}
export default Contact
