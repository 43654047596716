import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import roleConstant from '../../constants/roles.constant'
import Admins from '../Collections/Admins'
import Blogs from '../Collections/Blogs'
import Contacts from '../Collections/Contacts'
import DesignWorks from '../Collections/DesignWorks'
import FitoutProjects from '../Collections/FitoutProjects'
import News from '../Collections/News'
import Services from '../Collections/Services'
import Teams from '../Collections/Teams'
import Testimonials from '../Collections/Testimonials'
import Videos from '../Collections/Videos'
import Admin from '../Documents/Admin'
import Blog from '../Documents/Blog'
import Category from '../Documents/Category'
import Contact from '../Documents/Contact'
import DesignWork from '../Documents/DesignWork'
import FitoutProject from '../Documents/FitoutProject'
import Image from '../Documents/Image'
import Profile from '../Documents/Profile'
import Project from '../Documents/Project'
import Service from '../Documents/Service'
import SingleNews from '../Documents/SingleNews'
import Team from '../Documents/Team'
import Testimonial from '../Documents/Testimonial'
import Video from '../Documents/Video'
import RoleBasedGuard from '../Guards/RoleBasedGuard'

const MainRoutes = function () {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/blogs" />} />
      <Route path="/profile" element={<Profile />} />
      <Route
        path="/admins"
        element={
          <RoleBasedGuard permitedRoles={[roleConstant.ADMIN]}>
            <Admins />
          </RoleBasedGuard>
        }
      />
      <Route
        path="/admins/:id"
        element={
          <RoleBasedGuard permitedRoles={[roleConstant.ADMIN]}>
            <Admin />
          </RoleBasedGuard>
        }
      />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/blogs/:id" element={<Blog />} />
      <Route path="/categories/:id" element={<Category />} />
      <Route path="/contacts" element={<Contacts />} />
      <Route path="/contacts/:id" element={<Contact />} />
      <Route path="/design-works" element={<DesignWorks />} />
      <Route path="/design-works/:id" element={<DesignWork />} />
      <Route path="/fitout-projects" element={<FitoutProjects />} />
      <Route path="/fitout-projects/:id" element={<FitoutProject />} />
      <Route path="/news" element={<News />} />
      <Route path="/news/:id" element={<SingleNews />} />
      <Route path="/projects/:id" element={<Project />} />
      <Route path="/services" element={<Services />} />
      <Route path="/services/:id" element={<Service />} />
      <Route path="/teams" element={<Teams />} />
      <Route path="/teams/:id" element={<Team />} />
      <Route path="/testimonials" element={<Testimonials />} />
      <Route path="/testimonials/:id" element={<Testimonial />} />
      <Route path="/videos" element={<Videos />} />
      <Route path="/videos/:id" element={<Video />} />
      <Route path="/images/:id/:subId" element={<Image />} />
    </Routes>
  )
}
export default MainRoutes
