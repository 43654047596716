import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import schemaUtils from '../../_helpers/schemaUtils'
import translateUtils from '../../_helpers/translateUtils'
import { userService } from '../../_services'
import userSchema from '../../schemas/users.schema'
import AbstractLayout from '../Abstract/AbstractLayout'

const Admin = function () {
  const collectionName = 'Admins'
  const singleCollectionName = 'Admin'
  const queryKey = collectionName.toLowerCase()
  const singleQueryKey = singleCollectionName.toLowerCase()
  const params = useParams()
  const getQuery = reactQuery.getOneQuery(
    singleQueryKey,
    params.id,
    userService.get
  )
  const updateMutation = reactQuery.updateMutation(
    userService.update,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const deleteMutation = reactQuery.deleteMutation(
    userService.remove,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const [body, setBody] = useState({})
  return (
    <AbstractLayout
      actions={[
        {
          buttonLabel: `${translateUtils.translate(
            'Update'
          )} ${translateUtils.translate('Password')}`,
          buttonColor: 'primary',
          onSubmit: (args) => updateMutation.mutate(args),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translateUtils.translate(
              'Update'
            )} ${translateUtils.translate('Password')}`,
            inputs: schemaUtils.schemaToInputsArray(
              userSchema,
              getQuery.data,
              true,
              [],
              ['password']
            ),
          },
        },
        {
          buttonLabel: `${translateUtils.translate(
            'Update'
          )} ${translateUtils.translate(singleCollectionName)}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            const newBody = objectUtils.compare(getQuery.data, args)
            if (Object.keys(newBody).length)
              return updateMutation.mutate(newBody)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translateUtils.translate(
              'Update'
            )} ${translateUtils.translate(singleCollectionName)}`,
            inputs: schemaUtils.schemaToInputsArray(
              userSchema,
              getQuery.data,
              false,
              ['password', 'role', 'verified', 'createdAt']
            ),
          },
        },
        {
          buttonLabel: `${translateUtils.translate(
            'Delete'
          )} ${translateUtils.translate(singleCollectionName)}`,
          buttonColor: 'secondary',
          onSubmit: () => deleteMutation.mutate(),
          form: {
            title: `${translateUtils.translate(
              'Delete'
            )} ${translateUtils.translate(singleCollectionName)}`,
          },
          confirm: true,
          info: `${translateUtils.translate(
            'Are you sure you want to delete this'
          )} ${translateUtils.translate(singleCollectionName)}`,
        },
      ]}
      title={singleCollectionName}
      getQuery={getQuery}
      many={false}
    />
  )
}
export default Admin
