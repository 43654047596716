import React, { useState } from 'react'

// import { useSearchParams } from 'react-router-dom'
import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import schemaUtils from '../../_helpers/schemaUtils'
import translateUtils from '../../_helpers/translateUtils'
import { userService } from '../../_services'
import roleConstant from '../../constants/roles.constant'
import userSchema from '../../schemas/users.schema'
import AbstractLayout from '../Abstract/AbstractLayout'

const Admins = function () {
  const collectionName = 'Admins'
  const singleCollectionName = 'Admin'
  const queryKey = collectionName.toLowerCase()
  const [body, setBody] = useState({})
  const defaultQueryParams = { role: roleConstant.ADMIN }
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const getQuery = reactQuery.getQuery(queryKey, queryParams, userService.query)
  const createMutation = reactQuery.createMutation(
    userService.create,
    singleCollectionName,
    queryKey
  )
  // const [searchParams, setSearchParams] = useSearchParams()
  return (
    <AbstractLayout
      tableActions={[
        // {
        //   buttonLabel: translateUtils.translate('Filters'),
        //   buttonColor: 'primary',
        //   onSubmit: (args) =>
        //     setSearchParams({
        //       ...Object.fromEntries([...searchParams]),
        //       ...args,
        //       query: collectionName,
        //     }),
        //   body,
        //   setBody,
        //   withClear: true,
        //   clearFunction: () => setBody({}),
        //   withForm: true,
        //   form: {
        //     title: `${translateUtils.translate(
        //       'Filter'
        //     )} ${translateUtils.translate(singleCollectionName)}`,
        //     inputs: schemaUtils.schemaToInputsArray(
        //       userSchema,
        //       {
        //         ...queryParams,
        //         ...(queryParams.verified && {
        //           verified: JSON.parse(queryParams.verified),
        //         }),
        //       },
        //       false,
        //       [],
        //       ['verified', 'role']
        //     ),
        //   },
        //   withReset: true,
        //   resetFunction: () => setSearchParams(),
        // },
        {
          buttonLabel: `${translateUtils.translate(
            'Add'
          )} ${translateUtils.translate(singleCollectionName)}`,
          buttonColor: 'primary',
          onSubmit: (args) => createMutation.mutate(args),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translateUtils.translate(
              'Add'
            )} ${translateUtils.translate(singleCollectionName)}`,
            inputs: schemaUtils.schemaToInputsArray(userSchema, {}, true, [
              'role',
              'verified',
              'createdAt',
            ]),
          },
        },
      ]}
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
      notToShow={Object.keys(
        objectUtils.omit(userSchema, [
          'name',
          'email',
          'cellPhone',
          'createdAt',
        ])
      )}
    />
  )
}
export default Admins
