import React from 'react'

import typeConstant from '../../constants/types.constant'
import AbstractCollection from '../Abstract/AbstractCollection'

const Videos = function () {
  return (
    <AbstractCollection
      collectionName="Videos"
      singleCollectionName="Video"
      notToShow={[
        'id',
        'ancestorIds',
        'parentId',
        'images',
        'videos',
        'type',
        'createdAt',
        'nameAr',
        'content',
        'contentAr',
        'coverPhoto',
        'coverImageAlt',
        'coverImageAltAr',
        'metaDescription',
        'metaDescriptionAr',
        'windowTitle',
        'windowTitleAr',
      ]}
      toShow={['name']}
      type={typeConstant.VIDEO}
    />
  )
}
export default Videos
