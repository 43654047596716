import React from 'react'

import typeConstant from '../../constants/types.constant'
import AbstractCollection from '../Abstract/AbstractCollection'

const Teams = function () {
  return (
    <AbstractCollection
      collectionName="Teams"
      singleCollectionName="Team"
      notToShow={[
        'ancestorIds',
        'parentId',
        'images',
        'videos',
        'type',
        'createdAt',
      ]}
      toShow={['name', 'coverPhoto', 'createdAt']}
      type={typeConstant.TEAM}
    />
  )
}
export default Teams
