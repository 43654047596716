import React from 'react'

import AbstractDocument from '../Abstract/AbstractDocument'

const Project = function () {
  return (
    <AbstractDocument
      singleCollectionName="Project"
      notToShow={['ancestorIds', 'parentId', 'type']}
    />
  )
}
export default Project
