import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import schemaUtils from '../../_helpers/schemaUtils'
import translateUtils from '../../_helpers/translateUtils'
import { filterService } from '../../_services'
import filterSchema from '../../schemas/filter.schema'
import AbstractLayout from './AbstractLayout'

const AbstractDocument = function ({
  singleCollectionName,
  notToShow,
  update = true,
}) {
  const queryKey = 'filters'
  const singleQueryKey = 'filter'
  const params = useParams()
  const getQuery = reactQuery.getOneQuery(
    singleQueryKey,
    params.id,
    filterService.get
  )
  const updateMutation = reactQuery.updateMutation(
    filterService.update,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const deleteMutation = reactQuery.deleteMutation(
    filterService.remove,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const appendImagesMutation = reactQuery.updateMutation(
    filterService.addImages,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const prependImagesMutation = reactQuery.updateMutation(
    filterService.addImages,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey,
    { prepend: true }
  )
  const [body, setBody] = useState({})
  const action = (actionName, mutation) => ({
    buttonLabel: `${translateUtils.translate(
      actionName
    )} ${translateUtils.translate('Images')}`,
    buttonColor: 'primary',
    onSubmit: async (args) => {
      const promises = Object.keys(args.images).forEach((key) => {
        const formData = new FormData()
        formData.append('images', args.images[key])
        return new Promise((resolve, reject) => {
          mutation.mutate(formData, {
            onSuccess: () => resolve(),
            onError: (error) => reject(error),
          })
        })
      })
      await Promise.all(promises)
    },
    body,
    setBody,
    withClear: true,
    clearFunction: () => setBody({}),
    form: {
      title: `${translateUtils.translate(
        actionName
      )} ${translateUtils.translate('Images')}`,
      inputs: schemaUtils.schemaToInputsArray(
        filterSchema,
        getQuery.data,
        false,
        undefined,
        ['images']
      ),
    },
  })
  return (
    <AbstractLayout
      actions={[
        ...(update
          ? [
              ...(!notToShow.includes('images')
                ? [
                    action('Prepend', prependImagesMutation),
                    action('Append', appendImagesMutation),
                  ]
                : []),
              {
                buttonLabel: `${translateUtils.translate(
                  'Update'
                )} ${translateUtils.translate(singleCollectionName)}`,
                buttonColor: 'primary',
                onSubmit: (args) => {
                  const newBody = objectUtils.compare(getQuery.data, args)
                  const { coverPhoto, images } = newBody
                  delete newBody.coverPhoto
                  delete newBody.images
                  const formData = objectUtils.getFormData(newBody)
                  if (coverPhoto && coverPhoto.length)
                    Object.keys(coverPhoto).forEach((key) =>
                      formData.append('coverPhoto', coverPhoto[key])
                    )
                  if (images && images.length)
                    Object.keys(images).forEach((key) =>
                      formData.append('images', images[key])
                    )
                  if (
                    Object.keys(newBody).length ||
                    (coverPhoto && coverPhoto.length) ||
                    (images && images.length)
                  )
                    return updateMutation.mutate(formData)
                },
                body,
                setBody,
                withClear: true,
                clearFunction: () => setBody({}),
                form: {
                  title: `${translateUtils.translate(
                    'Update'
                  )} ${translateUtils.translate(singleCollectionName)}`,
                  inputs: schemaUtils.schemaToInputsArray(
                    filterSchema,
                    getQuery.data,
                    false,
                    notToShow
                  ),
                },
              },
            ]
          : []),
        {
          buttonLabel: `${translateUtils.translate(
            'Delete'
          )} ${translateUtils.translate(singleCollectionName)}`,
          buttonColor: 'secondary',
          onSubmit: () => deleteMutation.mutate(),
          form: {
            title: `${translateUtils.translate(
              'Delete'
            )} ${translateUtils.translate(singleCollectionName)}`,
          },
          confirm: true,
          info: `${translateUtils.translate(
            'Are you sure you want to delete this'
          )} ${translateUtils.translate(singleCollectionName)}`,
        },
      ]}
      title={singleCollectionName}
      getQuery={getQuery}
      many={false}
      notToShow={notToShow}
      navigate={['images', params.id, '_id']}
    />
  )
}
export default AbstractDocument
