import translateUtils from './translateUtils'

const currencyString = (value, currency) => {
  const currencyFormatter = new Intl.NumberFormat('en-EG', {
    style: 'currency',
    currency: currency || 'EGP',
  })
  return currencyFormatter.format(value)
}
const camelCase = (name) => {
  const camelcase = name
    .replace(/([A-Z]+)/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase())
    .trim()
  return translateUtils.translate(camelcase, true) || camelcase
}
const IsJsonString = (str) => {
  try {
    return JSON.parse(str)
  } catch (e) {
    return false
  }
}
const boolean = (value) =>
  value ? translateUtils.translate('Yes') : translateUtils.translate('No')
const twoDigit = (number) =>
  number.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })
const dayIndex = (index) =>
  [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ][index]
const queryString = (options) =>
  Object.keys(options)
    .map((key) => `${options[key] ? `&${key}=${options[key]}` : ''}`)
    .join('')
export default {
  camelCase,
  currencyString,
  IsJsonString,
  boolean,
  twoDigit,
  dayIndex,
  queryString,
}
