import axios from '../_helpers/axios'
import stringUtils from '../_helpers/stringUtils'

const route = 'filters'
const create = async (createBody) =>
  axios.post(`v1/${route}`, createBody, { transformRequest: () => createBody })
const query = async (options) =>
  axios.get(`v1/${route}?${stringUtils.queryString(options)}`)
const get = async (id) => axios.get(`v1/${route}/${id}`)
const update = async (id, updateBody) =>
  axios.patch(`v1/${route}/${id}`, updateBody, {
    transformRequest: () => updateBody,
  })
const remove = async (id) => axios.delete(`v1/${route}/${id}`)
const addImages = async (id, updateBody, options = {}) =>
  axios.put(
    `v1/${route}/${id}${options.prepend ? '?prepend=true' : ''}`,
    updateBody,
    { transformRequest: () => updateBody }
  )
const getImage = async (id, imageId) =>
  axios.get(`v1/${route}/images/${id}/${imageId}`)
const updateImage = async (id, imageId, updateBody) =>
  axios.patch(`v1/${route}/images/${id}/${imageId}`, updateBody)
const pullImage = async (id, imageId) =>
  axios.delete(`v1/${route}/images/${id}/${imageId}`)
export default {
  create,
  query,
  get,
  update,
  remove,
  addImages,
  getImage,
  updateImage,
  pullImage,
}
