import React from 'react'

import typeConstant from '../../constants/types.constant'
import AbstractCollection from '../Abstract/AbstractCollection'

const News = function () {
  return (
    <AbstractCollection
      collectionName="News"
      singleCollectionName="Single News"
      notToShow={['ancestorIds', 'parentId', 'images', 'videos', 'type']}
      toShow={['name', 'coverPhoto', 'createdAt']}
      type={typeConstant.NEWS}
    />
  )
}
export default News
