import React from 'react'

import typeConstant from '../../constants/types.constant'
import AbstractCollection from '../Abstract/AbstractCollection'

const FitoutProjects = function () {
  return (
    <AbstractCollection
      collectionName="Fitout Projects"
      singleCollectionName="Fitout Project"
      notToShow={[
        'ancestorIds',
        'parentId',
        'images',
        'videos',
        'type',
        'createdAt',
      ]}
      toShow={['name', 'coverPhoto', 'createdAt']}
      type={typeConstant.FITOUT}
    />
  )
}
export default FitoutProjects
