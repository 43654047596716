import React from 'react'

import AbstractDocument from '../Abstract/AbstractDocument'

const Category = function () {
  return (
    <AbstractDocument
      singleCollectionName="Category"
      notToShow={['ancestorIds', 'parentId', 'type']}
    />
  )
}
export default Category
