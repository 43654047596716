import getInput from '../components/Abstract/DialogInputs'
import regexNameConstant from '../constants/regexNames.constant'
import schemas from '../schemas'
import stringUtils from './stringUtils'

const schemaToInputsArray = (
  schema,
  values = {},
  required = false,
  notToShow = ['createdAt'],
  toShow = Object.keys(schema)
) => {
  const inputs = []
  Object.keys(schema).forEach((key) => {
    const type = getKeyType(key, schema)
    let require = false
    if (required && schema[key].required) require = true
    let options
    if (type === 'select') {
      options = schema[key].enum.map((option) => ({
        label: stringUtils.camelCase(option),
        value: option,
      }))
    }
    let queryKey
    let getApi
    let keys
    let value
    if (['mongoObjectId', 'mongoObjectIdMultiple'].includes(type)) {
      options = schema[key].options || {}
      queryKey = schema[key].queryKey
      getApi = schema[key].getApi
      keys = schema[key].keys
      if (values[key])
        value = Object.values(schema[key].keys).reduce((obj, v) => {
          obj[v] = values[v]
          return obj
        }, {})
    }
    let freeSolo
    if (['stringMultiple'].includes(type)) {
      freeSolo = schema[key].freeSolo
    }
    let accept
    if (type === 'file') {
      accept = schema[key].accept
    }
    if (!notToShow.includes(key) && toShow.includes(key) && getInput(type))
      inputs.push({
        name: key,
        label: stringUtils.camelCase(key),
        type,
        ...(values[key] && { value: value || values[key] }),
        ...(options && { options }),
        ...(queryKey && { queryKey }),
        ...(getApi && { getApi }),
        ...(keys && { keys }),
        ...(freeSolo && { freeSolo }),
        ...(accept && { accept }),
        required: require,
      })
  })
  return inputs
}

const getKeyType = (key, schema = schemas) => {
  let type
  if (Array.isArray(schema[key].type)) type = [schema[key].type]
  else type = schema[key].type
  if (schema[key].format) type = schema[key].format
  if (schema[key].enum) type = 'select'
  if (schema[key].pattern)
    type = regexNameConstant[new RegExp(schema[key].pattern)]
  if (schema[key].type === 'array')
    type = `${getKeyType('items', schema[key])}Multiple`
  return type
}

export default {
  schemaToInputsArray,
  getKeyType,
}
