import { jwtDecode } from "jwt-decode";

import localStorageUtils from './localStorageUtils'

const getUserRole = () => {
  const ACCESS_TOKEN = localStorageUtils.get(
    localStorageUtils.availableKeys.ACCESS_TOKEN
  )
  return jwtDecode(ACCESS_TOKEN).role
}
export default {
  getUserRole,
}
