const { filterService } = require('../_services')
const regexConstant = require('../constants/regexes.constant')
const typeConstant = require('../constants/types.constant')

const filterSchema = {
  id: { type: 'string' },
  parentId: {
    type: 'string',
    pattern: regexConstant.mongoObjectId.source,
    required: true,
    queryKey: 'filters',
    getApi: filterService.query,
    options: {},
    keys: {
      name: 'parentName',
      _id: 'parentId',
    },
  },
  ancestorIds: { type: 'array', items: { type: 'string' } },
  name: { type: 'string', transform: ['trim'], required: true },
  nameAr: { type: 'string', transform: ['trim'], required: true },
  content: { type: 'content' },
  contentAr: { type: 'content' },
  type: { type: 'string', enum: Object.values(typeConstant), required: true },
  coverPhoto: { type: 'file', accept: 'image/*', required: true },
  images: { type: 'file', accept: 'image/*' },
  videos: {
    type: 'array',
    items: { type: 'string', transform: ['trim'] },
    freeSolo: true,
  },
  createdAt: { type: ['string', 'null'], format: 'date-time' },
  coverImageAlt: { type: 'string', transform: ['trim'], required: true },
  coverImageAltAr: { type: 'string', transform: ['trim'], required: true },
  windowTitle: { type: 'string', transform: ['trim'], required: true },
  windowTitleAr: { type: 'string', transform: ['trim'], required: true },
  metaDescription: { type: 'string', transform: ['trim'], required: true },
  metaDescriptionAr: { type: 'string', transform: ['trim'], required: true },
}

module.exports = filterSchema
