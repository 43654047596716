import React from 'react'

import AbstractDocument from '../Abstract/AbstractDocument'

const SingleNews = function () {
  return (
    <AbstractDocument
      singleCollectionName="Single News"
      notToShow={['ancestorIds', 'parentId', 'images', 'videos', 'type']}
    />
  )
}
export default SingleNews
