/**
 * Display content only if user has permitted role
 */
import React from 'react'

import tokenUtils from '../../_helpers/tokenUtils'
import Guard from './Guard'

const RoleBasedGuard = function (props) {
  return (
    <Guard condition={props.permitedRoles.includes(tokenUtils.getUserRole())}>
      {props.children}
    </Guard>
  )
}
export default RoleBasedGuard
