import { TextField } from '@mui/material'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import translateUtils from '../../../_helpers/translateUtils'

const Text = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    isValid: () => !(props.required && !value.length),
  }))
  const [value, setValue] = useState(props.value || '')
  useEffect(() => {
    let isValid = true
    if (props.required && !value.length) isValid = false
    props.setIsValids((s) => {
      s[props.index] = isValid
      return s
    })
    if (isValid)
      props.setIsValid(
        props.isValids.reduce((isAllValid, key, i) => {
          if (i === props.index) return isAllValid && isValid
          return isAllValid && key
        }, true)
      )
    else props.setIsValid(false)
    props.setBody((s) => ({ ...s, [props.name]: value }))
  }, [value])
  return (
    <TextField
      margin="normal"
      label={translateUtils.translate(props.label)}
      type="text"
      fullWidth
      InputLabelProps={{ shrink: true }}
      autoFocus={props.index === 0}
      value={value}
      onChange={(event) => setValue(event.target.value)}
      required={props.required}
      error={props.required && !value.length}
      {...(props.required &&
        !value.length && {
          helperText: `${translateUtils.translate(
            props.label
          )} ${translateUtils.translate('is required')}`,
        })}
      multiline
    />
  )
})
export default Text
