import Autocomplete from './Autocomplete'
import AutocompleteMultiple from './AutocompleteMultiple'
import AutocompleteMultiple2 from './AutocompleteMultiple2'
import Boolean from './Boolean'
import Content from './Content'
import Date from './Date'
import DateTime from './DateTime'
import Email from './Email'
import File from './File'
import Number from './Number'
import Password from './Password'
import Select from './Select'
import Text from './Text'
import Time from './Time'

const getInput = (type) => {
  switch (type) {
    case 'autocomplete':
    case 'mongoObjectId':
      return Autocomplete
    case 'autocompleteMultiple':
    case 'mongoObjectIdMultiple':
      return AutocompleteMultiple
    case 'autocompleteMultiple2':
    case 'stringMultiple':
      return AutocompleteMultiple2
    case 'boolean':
      return Boolean
    case 'content':
      return Content
    case 'date':
    case 'date-time':
      return Date
    case 'dateTime':
      return DateTime
    case 'email':
      return Email
    case 'file':
      return File
    case 'number':
    case 'integer':
      return Number
    case 'password':
      return Password
    case 'select':
      return Select
    case 'string':
      return Text
    case 'time':
      return Time
    default:
      return null
  }
}
export default getInput
