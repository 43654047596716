import React from 'react'
import { useParams } from 'react-router-dom'

import reactQuery from '../../_helpers/reactQuery'
import { filterService } from '../../_services'
import typeConstant from '../../constants/types.constant'
import AbstractCollection from '../Abstract/AbstractCollection'
import AbstractDocument from '../Abstract/AbstractDocument'

const DesignWork = function () {
  const singleQueryKey = 'filter'
  const params = useParams()
  const getQuery = reactQuery.getOneQuery(
    singleQueryKey,
    params.id,
    filterService.get
  )
  return (
    <>
      <AbstractDocument
        singleCollectionName="Design Work"
        notToShow={['ancestorIds', 'parentId', 'images', 'videos', 'type']}
      />
      <AbstractCollection
        collectionName="Categories"
        singleCollectionName="Category"
        notToShow={['ancestorIds', 'parentId', 'videos', 'type', 'createdAt']}
        toShow={['name', 'coverPhoto', 'createdAt']}
        type={typeConstant.CATEGORY}
        parentId={getQuery.data ? getQuery.data.id : null}
        ancestorIds={
          getQuery.data
            ? `${
                getQuery.data.ancestorIds.length
                  ? `${getQuery.data.ancestorIds.join('')},`
                  : ''
              }${getQuery.data.id}`
            : null
        }
        navigate={['categories', '_id']}
      />
      <AbstractCollection
        collectionName="Projects"
        singleCollectionName="Project"
        notToShow={['ancestorIds', 'parentId', 'videos', 'type', 'createdAt']}
        toShow={['name', 'coverPhoto', 'createdAt']}
        type={typeConstant.PROJECT}
        parentId={getQuery.data ? getQuery.data.id : null}
        ancestorIds={
          getQuery.data
            ? `${
                getQuery.data.ancestorIds.length
                  ? `${getQuery.data.ancestorIds.join('')},`
                  : ''
              }${getQuery.data.id}`
            : null
        }
        navigate={['projects', '_id']}
      />
    </>
  )
}
export default DesignWork
