import React from 'react'

import AbstractDocument from '../Abstract/AbstractDocument'

const Testimonial = function () {
  return (
    <AbstractDocument
      singleCollectionName="Testimonial"
      notToShow={['ancestorIds', 'parentId', 'images', 'videos', 'type']}
    />
  )
}
export default Testimonial
