import 'react-quill/dist/quill.snow.css'
import 'quill-image-uploader/dist/quill.imageUploader.min.css'

import ImageResize from '@deedmob/quill-image-resize-alt-module'
import { InputLabel } from '@mui/material'
// import { ImageActions } from '@xeger/quill-image-actions'
// import { ImageFormats } from '@xeger/quill-image-formats'
import ImageUploader from 'quill-image-uploader'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import ReactQuill, { Quill } from 'react-quill'

import reactQuery from '../../../_helpers/reactQuery'
import translateUtils from '../../../_helpers/translateUtils'
import { imageService } from '../../../_services'

const BaseImageFormat = Quill.import('formats/image')
const ImageFormatAttributesList = ['alt', 'height', 'width', 'style']
class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute)
      }
      return formats
    }, {})
  }

  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value)
      } else {
        this.domNode.removeAttribute(name)
      }
    } else {
      super.format(name, value)
    }
  }
}

Quill.register('modules/imageUploader', ImageUploader)
Quill.register('modules/imageResize', ImageResize)
Quill.register(ImageFormat, true)
// Quill.register('modules/imageActions', ImageActions)
// Quill.register('modules/imageFormats', ImageFormats)

let createMutation

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ direction: 'rtl' }], // text direction
    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ header: [3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize', 'Toolbar'],
  },
  imageUploader: {
    upload: (file) => {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('images', file)
        createMutation
          .mutateAsync(formData)
          .then((data) => resolve(data))
          .catch((error) => reject(error.message))
      })
    },
  },
  // imageActions: {},
  // imageFormats: {},
}

const Content = forwardRef((props, ref) => {
  createMutation = reactQuery.createMutation(
    imageService.create,
    'Image',
    'images'
  )
  useImperativeHandle(ref, () => ({
    isValid: () => !(props.required && !value.length),
  }))
  const [value, setValue] = useState(props.value || '')
  useEffect(() => {
    let isValid = true
    if (props.required && !value.length) isValid = false
    props.setIsValids((s) => {
      s[props.index] = isValid
      return s
    })
    if (isValid)
      props.setIsValid(
        props.isValids.reduce((isAllValid, key, i) => {
          if (i === props.index) return isAllValid && isValid
          return isAllValid && key
        }, true)
      )
    else props.setIsValid(false)
    props.setBody((s) => ({ ...s, [props.name]: value }))
  }, [value])
  return (
    <>
      <InputLabel>{translateUtils.translate(props.label)}</InputLabel>
      <ReactQuill
        value={value}
        onChange={setValue}
        modules={modules}
        formats={[
          'align',
          'background',
          'blockquote',
          'bold',
          'code-block',
          'color',
          'float',
          'font',
          'header',
          'image',
          'italic',
          'link',
          'script',
          'strike',
          'size',
          'underline',
          'video',
          ...ImageFormatAttributesList,
        ]}
        theme="snow"
      />
    </>
  )
})
export default Content
