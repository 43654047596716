const formatSeconds = (seconds) =>
  new Date(seconds * 1000).toISOString().substring(11, 19)
const formatDate = (date) =>
  date ? new Date(date).toLocaleDateString('en-EG') : ''
const formatDateTime = (date) =>
  date
    ? `${formatDate(date)} ${new Date(date).toLocaleTimeString('en-EG')}`
    : ''
const formatDatePicker = (date) =>
  date ? new Date(date).toLocaleDateString('en-CA') : ''
const formatTime = (date) => {
  if (date) {
    const min = date.split(':')[1]
    let hour = +date.split(':')[0]
    hour = (((hour - 2) % 24) + 24) % 24
    if (hour < 10) hour = `0${hour}`
    return new Date(`1970-01-01T${hour}:${min}:00Z`).toLocaleTimeString('en-EG')
  }
  return ''
}
export default {
  formatSeconds,
  formatDate,
  formatDateTime,
  formatDatePicker,
  formatTime,
}
