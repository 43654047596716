import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'

import translateUtils from '../../_helpers/translateUtils'
import { authService } from '../../_services'
import Logo from '../../assets/images/logo.png'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      width: theme.spacing(80),
      height: theme.spacing(80),
      position: 'fixed',
      top: '50%',
      left: '50%',
      /* bring your own prefixes */
      transform: 'translate(-50%, -50%)',
      borderRadius: '3%',
    },
    backgroundColor: '#18191b',
    height: '100vh',
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    boxShadow:
      '0 0px 8px 0 rgba(0, 0, 0, 0.1), 0 0px 20px 0 rgba(0, 0, 0, 0.1)',
    width: '60%',
  },
  logo: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
  },
  tpd: {
    fontSize: '26px',
  },
  button: {
    margin: theme.spacing(2),
    borderRadius: '10px',
    fontWeight: 'bold',
    width: '200px',
  },
}))
const Login = function () {
  const classes = useStyles()
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const login = () => {
    event.preventDefault()
    event.stopPropagation()
    authService
      .login({ email, password })
      .then(() =>
        enqueueSnackbar(
          `${translateUtils.translate('Logged in')} ${translateUtils.translate(
            'successfully'
          )}`,
          {
            variant: 'success',
            TransitionProps: { direction: 'left' },
          }
        )
      )
      .catch((err) =>
        enqueueSnackbar(
          `${translateUtils.translate('Login')} ${translateUtils.translate(
            'Error'
          )}: ${err.response.data.message}`,
          { variant: 'error', TransitionProps: { direction: 'left' } }
        )
      )
  }
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword((s) => !s)
  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <img src={Logo} className={clsx(classes.logo)} width="60%" alt="" />
          <Typography variant="h3" gutterBottom className={clsx(classes.tpd)}>
            {translateUtils.translate('Algedra Dashboard')}
          </Typography>
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
            onSubmit={login}
          >
            <TextField
              className={clsx(classes.textField)}
              margin="normal"
              label={translateUtils.translate('Email')}
              type="text"
              InputLabelProps={{ shrink: true }}
              autoFocus
              autoComplete="new-password"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
            />
            <TextField
              className={clsx(classes.textField)}
              margin="normal"
              label={translateUtils.translate('Password')}
              type={showPassword ? 'text' : 'password'}
              InputLabelProps={{ shrink: true }}
              autoComplete="current-password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              size="large"
              variant="contained"
              color="primary"
              className={classes.button}
              type="submit"
            >
              {translateUtils.translate('Login')}
            </Button>
          </form>
        </Grid>
      </Paper>
    </div>
  )
}
export default Login
