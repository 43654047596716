import React, { useEffect, useState } from 'react'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import schemaUtils from '../../_helpers/schemaUtils'
import translateUtils from '../../_helpers/translateUtils'
import { filterService } from '../../_services'
import filterSchema from '../../schemas/filter.schema'
import AbstractLayout from './AbstractLayout'

const AbstractCollection = function ({
  collectionName,
  singleCollectionName,
  notToShow,
  toShow,
  type,
  parentId,
  ancestorIds,
  navigate,
}) {
  const queryKey = 'filters'
  const [body, setBody] = useState({})
  const defaultQueryParams = {
    type,
    ...(parentId && { parentId }),
    ...(ancestorIds && { ancestorIds }),
  }
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  useEffect(
    () => setQueryParams((s) => ({ ...s, ...defaultQueryParams })),
    [parentId, ancestorIds]
  )
  const getQuery = reactQuery.getQuery(
    queryKey,
    queryParams,
    filterService.query
  )
  const createMutation = reactQuery.createMutation(
    filterService.create,
    singleCollectionName,
    queryKey,
    [
      {
        type,
        ...(parentId && { parentId }),
        ...(ancestorIds && { ancestorIds }),
      },
    ]
  )
  // if (parentId && !getQuery.data?.count) return
  return (
    <AbstractLayout
      tableActions={[
        {
          buttonLabel: `${translateUtils.translate(
            'Add'
          )} ${translateUtils.translate(singleCollectionName)}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            const { coverPhoto, images } = args
            delete args.coverPhoto
            delete args.images
            const formData = objectUtils.getFormData({
              ...args,
              type,
              ...(parentId && { parentId }),
              ...(ancestorIds && { ancestorIds }),
            })
            if (coverPhoto && coverPhoto.length)
              Object.keys(coverPhoto).forEach((key) =>
                formData.append('coverPhoto', coverPhoto[key])
              )
            if (images && images.length)
              Object.keys(images).forEach((key) =>
                formData.append('images', images[key])
              )
            return createMutation.mutate(formData)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translateUtils.translate(
              'Add'
            )} ${translateUtils.translate(singleCollectionName)}`,
            inputs: schemaUtils.schemaToInputsArray(
              filterSchema,
              {},
              true,
              notToShow
            ),
          },
        },
      ]}
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
      notToShow={Object.keys(objectUtils.omit(filterSchema, [...toShow]))}
      navigate={navigate}
    />
  )
}
export default AbstractCollection
