import { Autocomplete, TextField } from '@mui/material'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useDebounce } from 'use-debounce'

import reactQuery from '../../../_helpers/reactQuery'
import translateUtils from '../../../_helpers/translateUtils'

const AutoComplete = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    isValid: () => !(props.required && !Object.keys(value).length),
  }))
  const [value, setValue] = useState(props.value || {})
  useEffect(() => {
    let isValid = true
    if (props.required && !Object.keys(value).length) isValid = false
    props.setIsValids((s) => {
      s[props.index] = isValid
      return s
    })
    if (isValid)
      props.setIsValid(
        props.isValids.reduce((isAllValid, key, i) => {
          if (i === props.index) return isAllValid && isValid
          return isAllValid && key
        }, true)
      )
    else props.setIsValid(false)
    props.setBody((s) => ({ ...s, ...value }))
  }, [value])
  const [searchValue, setSearchValue] = useState()
  const [debouncedSearch] = useDebounce(searchValue, 500)
  const getQuery = reactQuery.getQuery(
    props.queryKey,
    { ...(debouncedSearch && { search: debouncedSearch }), ...props.options },
    props.getApi
  )
  return (
    <Autocomplete
      margin="normal"
      loading={getQuery.isLoading}
      disablePortal
      fullWidth
      options={getQuery.data?.documents || []}
      getOptionLabel={(option) =>
        option[Object.keys(props.keys)[0]] ||
        option[Object.values(props.keys)[0]] ||
        ''
      }
      isOptionEqualToValue={(option, v) =>
        Object.keys(props.keys).reduce(
          (bool, key) => option[key] === v[props.keys[key]] && bool,
          true
        )
      }
      onChange={(_, newValue) =>
        setValue(
          Object.keys(props.keys).reduce((obj, key) => {
            obj[props.keys[key]] = newValue ? newValue[key] : ''
            return obj
          }, {})
        )
      }
      onInputChange={(_, newValue, reason) => {
        if (reason !== 'reset') setSearchValue(newValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="normal"
          label={translateUtils.translate(props.label)}
          type="text"
          fullWidth
          InputLabelProps={{ shrink: true }}
          autoFocus={props.index === 0}
          autoComplete="new-password"
          required={props.required}
          error={props.required && !Object.keys(value).length}
          {...(props.required &&
            !Object.keys(value).length && {
              helperText: `${translateUtils.translate(
                props.label
              )} ${translateUtils.translate('is required')}`,
            })}
        />
      )}
      value={value}
      disabled={props.disabled}
    />
  )
})
export default AutoComplete
