import React from 'react'

import AbstractDocument from '../Abstract/AbstractDocument'

const Team = function () {
  return (
    <AbstractDocument
      singleCollectionName="Team"
      notToShow={['ancestorIds', 'parentId', 'type']}
    />
  )
}
export default Team
