import React, { useState } from 'react'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import { contactService } from '../../_services'
import contactSchema from '../../schemas/contacts.schema'
import AbstractLayout from '../Abstract/AbstractLayout'

const Contacts = function () {
  const collectionName = 'Contacts'
  // const singleCollectionName = 'Contact'
  const queryKey = collectionName.toLowerCase()
  const defaultQueryParams = {}
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const getQuery = reactQuery.getQuery(
    queryKey,
    queryParams,
    contactService.query
  )
  return (
    <AbstractLayout
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
      notToShow={Object.keys(
        objectUtils.omit(contactSchema, ['name', 'createdAt'])
      )}
    />
  )
}
export default Contacts
