import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import schemaUtils from '../../_helpers/schemaUtils'
import translateUtils from '../../_helpers/translateUtils'
import { filterService } from '../../_services'
import imageSchema from '../../schemas/images.schema'
import AbstractLayout from '../Abstract/AbstractLayout'

const Image = function () {
  const singleCollectionName = 'Image'
  const singleQueryKey = 'filter'
  const params = useParams()
  const getQuery = reactQuery.subGetOneMutation(
    singleQueryKey,
    params.id,
    params.subId,
    filterService.getImage
  )
  const updateMutation = reactQuery.subUpdateMutation(
    filterService.updateImage,
    params.id,
    params.subId,
    singleCollectionName,
    singleQueryKey
  )
  const deleteMutation = reactQuery.subDeleteMutation(
    filterService.pullImage,
    params.id,
    params.subId,
    singleCollectionName,
    singleQueryKey
  )
  const [body, setBody] = useState({})
  return (
    <AbstractLayout
      actions={[
        {
          buttonLabel: `${translateUtils.translate(
            'Update'
          )} ${translateUtils.translate(singleCollectionName)}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            const newBody = objectUtils.compare(getQuery.data, args)
            if (Object.keys(newBody).length)
              return updateMutation.mutate(newBody)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translateUtils.translate(
              'Update'
            )} ${translateUtils.translate(singleCollectionName)}`,
            inputs: schemaUtils.schemaToInputsArray(
              imageSchema,
              getQuery.data,
              false,
              ['path']
            ),
          },
        },
        {
          buttonLabel: `${translateUtils.translate(
            'Delete'
          )} ${translateUtils.translate(singleCollectionName)}`,
          buttonColor: 'secondary',
          onSubmit: () => deleteMutation.mutate(),
          form: {
            title: `${translateUtils.translate(
              'Delete'
            )} ${translateUtils.translate(singleCollectionName)}`,
          },
          confirm: true,
          info: `${translateUtils.translate(
            'Are you sure you want to delete this'
          )} ${translateUtils.translate(singleCollectionName)}`,
        },
      ]}
      title={singleCollectionName}
      getQuery={getQuery}
      many={false}
    />
  )
}
export default Image
