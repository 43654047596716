import React from 'react'

import AbstractDocument from '../Abstract/AbstractDocument'

const Video = function () {
  return (
    <AbstractDocument
      singleCollectionName="Video"
      notToShow={[]}
      update={false}
    />
  )
}
export default Video
