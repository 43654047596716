import React from 'react'

import typeConstant from '../../constants/types.constant'
import AbstractCollection from '../Abstract/AbstractCollection'

const Blogs = function () {
  return (
    <AbstractCollection
      collectionName="Blogs"
      singleCollectionName="Blog"
      notToShow={['ancestorIds', 'parentId', 'images', 'videos', 'type']}
      toShow={['name', 'coverPhoto', 'createdAt']}
      type={typeConstant.BLOG}
    />
  )
}
export default Blogs
