import React, { useState } from 'react'

import reactQuery from '../../_helpers/reactQuery'
import schemaUtils from '../../_helpers/schemaUtils'
import translateUtils from '../../_helpers/translateUtils'
import { userService } from '../../_services'
import userSchema from '../../schemas/users.schema'
import AbstractLayout from '../Abstract/AbstractLayout'

const Profile = function () {
  const collectionName = 'Admins'
  const singleCollectionName = 'Admin'
  const queryKey = collectionName.toLowerCase()
  const singleQueryKey = singleCollectionName.toLowerCase()
  const getQuery = reactQuery.getOneQuery(
    singleQueryKey,
    'undefined',
    userService.get
  )
  const updateMutation = reactQuery.updateMutation(
    userService.update,
    'undefined',
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const [body, setBody] = useState({})
  return (
    <AbstractLayout
      actions={[
        {
          buttonLabel: `${translateUtils.translate(
            'Update'
          )} ${translateUtils.translate('Password')}`,
          buttonColor: 'primary',
          onSubmit: (args) => updateMutation.mutate(args),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translateUtils.translate(
              'Update'
            )} ${translateUtils.translate('Password')}`,
            inputs: schemaUtils.schemaToInputsArray(
              userSchema,
              getQuery.data,
              true,
              [],
              ['password']
            ),
          },
        },
      ]}
      title={singleCollectionName}
      getQuery={getQuery}
      many={false}
    />
  )
}
export default Profile
