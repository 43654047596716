import axios from 'axios'
import axiosRetry from 'axios-retry'

import localStorageUtils from './localStorageUtils'

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_API_URL
// axios.defaults.timeout = 15000
axios.defaults.headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'Accept-Language': 'en',
}
const axiosInstance = axios.create()
axiosInstance.interceptors.request.use((config) => {
  const ACCESS_TOKEN = localStorageUtils.get(
    localStorageUtils.availableKeys.ACCESS_TOKEN
  )
  const ACCESS_TOKEN_EXPIRY = localStorageUtils.get(
    localStorageUtils.availableKeys.ACCESS_TOKEN_EXPIRY
  )
  const REFRESH_TOKEN = localStorageUtils.get(
    localStorageUtils.availableKeys.REFRESH_TOKEN
  )
  if (ACCESS_TOKEN) config.headers.Authorization = `Bearer ${ACCESS_TOKEN}`
  const tenMinutesLater = new Date()
  tenMinutesLater.setMinutes(tenMinutesLater.getMinutes() + 10)
  if (ACCESS_TOKEN_EXPIRY && new Date(ACCESS_TOKEN_EXPIRY) <= tenMinutesLater) {
    axios
      .post(`v1/auth/refresh-tokens`, { token: REFRESH_TOKEN })
      .then(({ data }) => {
        localStorageUtils.set(
          localStorageUtils.availableKeys.ACCESS_TOKEN,
          data.access.token
        )
        localStorageUtils.set(
          localStorageUtils.availableKeys.ACCESS_TOKEN_EXPIRY,
          data.access.expiresAt
        )
        if (data.refresh)
          localStorageUtils.set(
            localStorageUtils.availableKeys.REFRESH_TOKEN,
            data.refresh.token
          )
      })
      .catch(() => {
        localStorageUtils.clear()
        window.dispatchEvent(new Event('storage'))
      })
  }
  return config
})
axiosInstance.interceptors.response.use((response) => response.data)
axiosRetry(axiosInstance, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
})
export default axiosInstance
