import { Dialog, DialogContent } from '@mui/material'
// import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import React, { useState } from 'react'
// import { useParams } from 'react-router-dom'

// import translateUtils from '../../../_helpers/translateUtils'
// import { filterService } from '../../../_services'

const ImageRender = function (props) {
  const [open, setOpen] = useState(false)
  // const params = useParams()

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <form action="/" method="POST" id="mainForm" onSubmit={() => {}}>
            <img
              style={{ maxWidth: '100%', maxHeight: '100%' }}
              src={`${
                !props.data.startsWith('http')
                  ? process.env.REACT_APP_MEDIA_SERVER
                  : ''
              }${props.data}`}
              alt=""
            />
            {/* <DialogActions>
              <Button type="submit" color="secondary" variant="contained">
                {translateUtils.translate('Delete')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(false)}
              >
                {translateUtils.translate('Cancel')}
              </Button>
            </DialogActions> */}
          </form>
        </DialogContent>
      </Dialog>
      <img
        style={{ maxWidth: '20vw', maxHeight: '20vh' }}
        src={`${
          !props.data.startsWith('http')
            ? process.env.REACT_APP_MEDIA_SERVER
            : ''
        }${props.data}`}
        alt=""
        onClick={() => setOpen(true)}
      />
    </>
  )
}
export default ImageRender
