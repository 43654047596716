import React from 'react'

import typeConstant from '../../constants/types.constant'
import AbstractCollection from '../Abstract/AbstractCollection'

const Services = function () {
  return (
    <AbstractCollection
      collectionName="Services"
      singleCollectionName="Service"
      notToShow={['ancestorIds', 'parentId', 'videos', 'type', 'createdAt']}
      toShow={['name', 'coverPhoto', 'createdAt']}
      type={typeConstant.SERVICES}
    />
  )
}
export default Services
