import React from 'react'

import typeConstant from '../../constants/types.constant'
import AbstractCollection from '../Abstract/AbstractCollection'

const DesignWorks = function () {
  return (
    <AbstractCollection
      collectionName="Design Works"
      singleCollectionName="Design Work"
      notToShow={[
        'ancestorIds',
        'parentId',
        'images',
        'videos',
        'type',
        'createdAt',
      ]}
      toShow={['name', 'coverPhoto', 'createdAt']}
      type={typeConstant.DESIGN}
    />
  )
}
export default DesignWorks
