import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import translateUtils from '../../../_helpers/translateUtils'

const Password = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    isValid: () =>
      !(props.required && /^(.{0,7}|[^0-9]*|[^a-z]*)$/.test(value)),
  }))
  const [value, setValue] = useState(props.value || '')
  useEffect(() => {
    let isValid = true
    if (props.required && /^(.{0,7}|[^0-9]*|[^a-z]*)$/.test(value))
      isValid = false
    props.setIsValids((s) => {
      s[props.index] = isValid
      return s
    })
    if (isValid)
      props.setIsValid(
        props.isValids.reduce((isAllValid, key, i) => {
          if (i === props.index) return isAllValid && isValid
          return isAllValid && key
        }, true)
      )
    else props.setIsValid(false)
    props.setBody((s) => ({ ...s, [props.name]: value }))
  }, [value])
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword((s) => !s)
  return (
    <TextField
      margin="normal"
      label={translateUtils.translate(props.label)}
      type={showPassword ? 'text' : 'password'}
      fullWidth
      InputLabelProps={{ shrink: true }}
      autoFocus={props.index === 0}
      autoComplete="new-password"
      value={value}
      onChange={(event) => setValue(event.target.value)}
      required={props.required}
      error={props.required && /^(.{0,7}|[^0-9]*|[^a-z]*)$/.test(value)}
      {...(props.required &&
        /^(.{0,7}|[^0-9]*|[^a-z]*)$/.test(value) && {
          helperText: `${translateUtils.translate(
            props.label
          )} ${translateUtils.translate(
            'password must be of minimum length 8 and contain at least 1 letter and 1 number'
          )}`,
        })}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleClickShowPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
})
export default Password
