const contactSchema = require('./contacts.schema')
const filterSchema = require('./filter.schema')
const imageSchema = require('./images.schema')
const userSchema = require('./users.schema')

const schemas = {
  ...contactSchema,
  ...filterSchema,
  ...imageSchema,
  ...userSchema,
}

export default schemas
