import { Checkbox, FormControlLabel } from '@mui/material'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import translateUtils from '../../../_helpers/translateUtils'

const Boolean = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    isValid: () => true,
  }))
  const [value, setValue] = useState(!!props.value)
  useEffect(() => {
    props.setIsValids((s) => {
      s[props.index] = true
      return s
    })
    props.setIsValid(
      props.isValids.reduce((isAllValid, key, i) => {
        if (i === props.index) return isAllValid && true
        return isAllValid && key
      }, true)
    )
    props.setBody((s) => ({ ...s, [props.name]: value }))
  }, [value])
  return (
    <FormControlLabel
      style={{ display: 'block' }}
      control={
        <Checkbox
          autoFocus={props.index === 0}
          color="primary"
          checked={value}
          onChange={(event) => setValue(event.target.checked)}
        />
      }
      label={translateUtils.translate(props.label)}
    />
  )
})
export default Boolean
