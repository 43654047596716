import React from 'react'

import typeConstant from '../../constants/types.constant'
import AbstractCollection from '../Abstract/AbstractCollection'

const Testimonials = function () {
  return (
    <AbstractCollection
      collectionName="Testimonials"
      singleCollectionName="Testimonial"
      notToShow={[
        'ancestorIds',
        'parentId',
        'images',
        'videos',
        'type',
        'createdAt',
      ]}
      toShow={['name', 'coverPhoto', 'createdAt']}
      type={typeConstant.TESTIMONIAL}
    />
  )
}
export default Testimonials
