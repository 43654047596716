const typeConstant = {
  DESIGN: 'design',
  FITOUT: 'fitout',
  CATEGORY: 'category',
  PROJECT: 'project',
  CITY: 'city',
  BLOG: 'blog',
  NEWS: 'news',
  TEAM: 'team',
  SERVICES: 'services',
  TESTIMONIAL: 'testimonial',
  VIDEO: 'video',
}

module.exports = typeConstant
