import React from 'react'

import AbstractDocument from '../Abstract/AbstractDocument'

const Blog = function () {
  return (
    <AbstractDocument
      singleCollectionName="Blog"
      notToShow={['ancestorIds', 'parentId', 'images', 'videos', 'type']}
    />
  )
}
export default Blog
